export const lazyLoadVideos = () => {
    const lazyVideos = [].slice.call(document.querySelectorAll("video"));

    if ("IntersectionObserver" in window) {
      const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (let source in video.target.children) {
              let videoSource = video.target.children[source];
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.getAttribute('data-src');
              }
            }
            video.target.load();
            lazyVideoObserver.unobserve(video.target);
          }
        })
      })

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      })
    }
}